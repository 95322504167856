<template>
  <div class="auth">
    <div class="wrapper signup">
      <div class="imgcontent">
        <img src="/images/signup.png" alt="" />
      </div>

      <div class="content">
        <div class="logo">
          <h1>
            <app-i18n code="app.title"></app-i18n>
          </h1>
        </div>

        <el-form
          :model="model"
          :rules="rules"
          @submit.prevent.native="doSubmit"
          class="form"
          label-position="left"
          label-width="0px"
          ref="form"
        >
          <el-form-item :prop="fields.email.name">
            <el-input
              :placeholder="fields.email.label"
              auto-complete="off"
              ref="focus"
              type="text"
              v-model.lazy.trim="model[fields.email.name]"
              class="input__shadow"
            ></el-input>
          </el-form-item>

          <el-form-item :prop="fields.password.name">
            <el-input
              :placeholder="fields.password.label"
              auto-complete="off"
              type="password"
              v-model.lazy.trim="model[fields.password.name]"
              class="input__shadow"
            ></el-input>
          </el-form-item>

          <transition name="fade">
            <el-form-item class="captcha-area-style" v-show="rcvisible">
              <vue-recaptcha
                ref="recaptcha"
                @verify="onRecaptchaVerify"
                :sitekey="rcsitekey"
                :loadRecaptchaScript="true"
                class="captcha-style"
              >
              </vue-recaptcha>
            </el-form-item>
          </transition>

          <el-form-item class="form-buttons">
            <el-button
              :loading="loading"
              class="w-100 btn-signup"
              @click="showCaptcha"
              type="primary"
              :disabled="rcvisible"
            >
              <app-i18n code="auth.signup"></app-i18n>
            </el-button>
          </el-form-item>
          

          <div class="other-actions">
            <router-link :to="{ path: '/auth/signin' }">
              <el-button type="text">
                <app-i18n code="auth.alreadyHaveAnAccount"></app-i18n>
              </el-button>
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import VueRecaptcha from 'vue-recaptcha';
import config from '@/config';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',
  components: {
    'vue-recaptcha': VueRecaptcha
  },
  data() {
    return {
      rcsitekey: config.RECAPTCHA_SITEKEY,
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules()
      },
      model: {},
      rcToken: null,
      rcvisible: false
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading'
    }),

    fields() {
      return fields;
    }
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword'
    }),

    onRecaptchaVerify(token) {
      this.rcToken = token;
      if (this.rcToken) this.doSubmit();
    },

    async showCaptcha() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.rcvisible = true;
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      await this.doRegisterEmailAndPassword({
        email: this.model.email.toLowerCase(),
        password: this.model.password,
        recaptchaToken: this.rcToken
      });
      this.rcToken = null;
      this.rcvisible = false;
      this.$refs.recaptcha.reset()
    }
  }
};
</script>
<style scoped>
.btn-signup {
  background-color: #132892;
}
.captcha-area-style {
  text-align: center;
}
.captcha-style {
  display: inline-block;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave {
}
.fade-leave-active {
  transition: opacity 1s;
  opacity: 0;
}
</style>
